.p-4-5 {
  padding: 2rem !important;
}

.embed-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.dashboard {
  background: #eee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 70px;

  .zero-state {
    width: 280px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      filter: invert(1);
      opacity: 0.3;
      width: 100%;
      margin-bottom: 48px;
    }
    h2,
    p {
      opacity: 0.8;
    }
    button {
      margin-top: 16px;
      opacity: 0.9;
    }
  }

  .socket {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .account {
    position: relative;
    height: 180px;
    overflow: hidden;

    .btn-close {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .info {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      right: 0;
      padding-left: 182px;
      transform: translateY(-50%);
      padding-right: 32px;

      .input-group-text {
        padding: 8px 10px;
      }
    }

    .splash {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100px;

      .icon {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background: #eee;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translate(-50%, -50%);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.splash-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ff0401, #fc6e02);

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(bg-md.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .form {
    width: 480px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    &.wide {
      width: 880px;
    }
    .midsp {
      margin: 45px 0;
    }
  }

  .logo {
    height: 100px;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .card {
    padding: 2rem;
    border-radius: 1rem;
  }

  .warning {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #da292e;
    color: #fff;
    border-radius: 8px;
    padding: 6px 12px;
  }

  .progress.strength {
    height: 8px;
  }

  .qr {
    display: block;
    margin: 0 auto;
  }

  .arrow-circle {
    background: #444;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    i {
      font-size: 24px;
      position: absolute;
      color: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.modal {
  .list-group {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: -1px;
  }

  .site-chosen {
    position: relative;
    .input-group-text {
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.circle-success {
  background: #0F9D58;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  color: #FFF;
  position: relative;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
  }
}
